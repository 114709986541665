import { CurrentView, FloorViewMode, SpaceFloorViewMode } from './-constants'
import { BuildingState } from './-types'

export default (): BuildingState => ({
  currentView: CurrentView.BUILDING,
  canvasElement: null,
  cdnBase: '',
  buildingCode: '',
  project: {},
  activeProject: '',
  client: '',
  space: {},
  isInitialized: false,
  floorViewMode: FloorViewMode.DEFAULT,
  spaceFloorViewMode: SpaceFloorViewMode.ALL,
  fitoutData: {
    initialized: false,
    data: []
  },
  loadingScreenActive: true,
  finishedLoadingAssets: false,
  settings: {},
  assetLoadingError: null,
  webGLContextLost: false
})
